/**
 * Handles the display of reveal.js' optional footer.
 */
export default class Footer {

	constructor( Reveal ) {

		this.Reveal = Reveal;

	}

	render() {

		this.element = document.createElement( 'div' );
		this.element.className = 'footer';
		this.Reveal.getRevealElement().appendChild( this.element );

	}

	/**
	 * Called when the reveal.js config is updated.
	 */
	configure( config, oldConfig ) {

		//let footerDisplay = 'none';
		this.element.style.display = true;

	}

	/**
	 * Updates the footer to match the current slide.
	 */
	update() {

		// Update slide number if enabled
		if( this.Reveal.getConfig().footer && this.element ) {
			this.element.innerHTML = '<div class="logo"><img src=./images/Logo_HochschuleTrier_Umweltcampus_WEB.svg></img></div>' +
				'<div class="title">' + this.Reveal.getConfig().subtitle_short + ' - ' + this.Reveal.getConfig().title + '</div>';
		}

	}

	destroy() {

		this.element.remove();

	}

}